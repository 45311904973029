<template>
  <div>
    <h4>
        <span>Prosjekter</span>
    </h4>
    <a-tabs default-active-key="1">
      <a-tab-pane :key="i+1" :tab="company.name" v-for="(company, i) in companies">
        <a-collapse accordion>
          <a-collapse-panel :key="j" :header="project.id.concat(' ',project.name)" v-for="(project, j) in company.projects">
            <h5 class="mb-4">
              <strong>{{ project.id.concat(' ',project.name) }}</strong>
            </h5>
            <a-form layout="vertical" @submit="handleSubmit(i, j)">
              <div class="row">
                <div class="col-md-6">
                  <a-form-item label="ID">
                    <a-input placeholder="ID" v-model="project.id" :disabled="!project.newProject"/>
                  </a-form-item>
                </div>
                <div class="col-md-6">
                  <a-form-item label="Navn">
                    <a-input placeholder="Navn" v-model="project.name" :disabled="!project.newProject"/>
                  </a-form-item>
                </div>
                <div class="col-12">
                  <a-form-item>
                    <button type="primary" class="btn btn-light px-5">Lagre</button>
                  </a-form-item>
                </div>
              </div>
            </a-form>
          </a-collapse-panel>
        </a-collapse>
        <button type="secondary" class="btn btn-light px-5" @click="addProject(company, i)">Opprett ny prosjekt</button>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'

export default {
  data() {
    return {
      companies: [],
    }
  },
  methods: {
    addProject(company, i) {
      this.companies[i].projects.push({
        id: 'NA',
        name: 'Ny Prosjekt',
        newProject: true,
        company: company.id,
      })
    },
    handleSubmit(i, j) {
      const project = this.companies[i].projects[j]
      console.log(project)
      if (project.newProject) {
        firestore.collection('settings').doc('projects').collection('projects')
          .add(project)
          .then(() => {
            openNotification('Informasjon om prosjekt lagret')
          })
      } else {
        firestore.collection('settings').doc('projects').collection('projects')
          .doc(project.cid)
          .set(project)
          .then(() => {
            openNotification('Informasjon om prosjekt lagret')
          })
      }
    },
  },
  mounted() {
    firestore.collection('settings').doc('companies').collection('companies')
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const company = {
            name: doc.data().name,
            id: doc.id,
            projects: [],
          }
          firestore.collection('settings').doc('projects').collection('projects')
            .where('company', '==', company.id)
            .get()
            .then(snapshot => {
              snapshot.forEach(doc => {
                const project = doc.data()
                project.cid = doc.id
                project.newProject = false
                company.projects.push(project)
              })
            })
          this.companies.push(company)
        })
        console.log(this.companies)
      })
  },
}
</script>
