<template>
  <div>
    <h4>
        <span>Avdelinger</span>
    </h4>
    <a-collapse accordion>
      <a-collapse-panel :key="i" :header="department.id.concat(' ',department.name)" v-for="(department, i) in departments">
        <h5 class="mb-4">
          <strong>{{ department.id.concat(' ',department.name) }}</strong>
        </h5>
        <a-form layout="vertical" @submit="handleSubmit(i)">
          <div class="row">
            <div class="col-md-6">
              <a-form-item label="ID">
                <a-input placeholder="ID" v-model="department.id" :disabled="!department.newDepartment"/>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Navn">
                <a-input placeholder="Navn" v-model="department.name" :disabled="!department.newDepartment"/>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Adress">
                <a-input placeholder="Slottsparken 1"  v-model="department.addressLine1" />
              </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="City">
                <a-input placeholder="Oslo" v-model="department.city" />
              </a-form-item>
            </div>
            <div class="col-md-2">
              <a-form-item label="Zip">
                <a-input placeholder="0001" v-model="department.postalCode" />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Org. nummer underenhet">
                <a-input placeholder="999999999"  v-model="department.organizationNumber" />
              </a-form-item>
            </div>
            <div class="col-12">
              <a-form-item>
                <button type="primary" class="btn btn-light px-5">Lagre</button>
              </a-form-item>
            </div>
          </div>
        </a-form>
      </a-collapse-panel>
    </a-collapse>
    <button type="secondary" class="btn btn-light px-5" @click="addDepartment">Opprett ny avdeling</button>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'

export default {
  data() {
    return {
      departments: [],
    }
  },
  methods: {
    addDepartment() {
      this.departments.push({
        id: 'NA',
        name: 'Ny Avdeling',
        newDepartment: true,
      })
    },
    handleSubmit(i) {
      const department = this.departments[i]
      console.log(department)
      firestore.collection('settings').doc('departments').collection('departments')
        .doc(department.id)
        .set(department)
        .then(() => {
          openNotification('Informasjon om avdeling lagret')
        })
    },
  },
  mounted() {
    firestore.collection('settings').doc('departments').collection('departments')
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const department = doc.data()
          department.newDepartment = false
          this.departments.push(department)
        })
      })
  },
}
</script>
