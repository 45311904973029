<template>
  <cui-acl :roles="['CompanySettings']" redirect>
    <div :class="$style.cui__sidebar__inner">
        <h5>
        <strong>Instillinger Sky Portalen</strong>
        </h5>
        <div class="cui__utils__line" :style="{ marginTop: '25px', marginBottom: '30px' }"></div>
        <div :class="$style.cui__sidebar__type" class="mb-4">
          <companies />
        </div>
        <div :class="$style.cui__sidebar__type" class="mb-4">
          <departments />
        </div>
        <div :class="$style.cui__sidebar__type__items">
        <projects />
        </div>
    </div>
  </cui-acl>
</template>

<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import departments from '@/components/custom/settings/departments'
import companies from '@/components/custom/settings/companies'
import projects from '@/components/custom/settings/projects'

export default {
  components: {
    cuiAcl,
    departments,
    projects,
    companies,
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
