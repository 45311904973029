<template>
  <div>
    <h4>
        <span>Selskaper</span>
    </h4>
    <a-collapse accordion>
      <a-collapse-panel :key="i" :header="company.name" v-for="(company, i) in companies">
        <h5 class="mb-4">
          <strong>{{ company.name }}</strong>
        </h5>
        <a-form layout="vertical" @submit="handleSubmit(i)">
          <div class="row">
            <div class="col-md-3">
              <a-form-item label="ID">
                <a-input placeholder="ID" v-model="company.id" :disabled="!company.newCompany"/>
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Navn">
                <a-input placeholder="Navn" v-model="company.name"/>
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Org. nummer selskap">
                <a-input placeholder="999999999"  v-model="company.organizationNumber" />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Selskapstype">
                <a-input placeholder="selskapstype"  v-model="company.type" />
                <!-- change with select -->
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Adress">
                <a-input placeholder="Slottsparken 1"  v-model="company.address.addressLine1" />
              </a-form-item>
            </div>
            <div class="col-md-2">
              <a-form-item label="Zip">
                <a-input placeholder="0001" v-model="company.address.postalCode" />
              </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="City">
                <a-input placeholder="Oslo" v-model="company.address.city" />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Display Name">
                <a-input placeholder="Display Name"  v-model="company.displayName" />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="E-post">
                <a-input placeholder="info@website.no"  v-model="company.email" />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Nettside">
                <a-input placeholder="website"  v-model="company.url" />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="E-post domene">
                <a-input placeholder="website.no"  v-model="company.emailDomain" />
              </a-form-item>
            </div>
            <div class="col-md-3">
              <a-form-item label="Telefonnummer">
                <a-input placeholder="456 78 910"  v-model="company.phoneNumber" />
              </a-form-item>
            </div>
            </div>
            <div class="row" v-for="(division, i ) in company.divisions" :key="i">
              <div class="col-md-4">
                <a-form-item label="Navn Underenhet">
                  <a-input placeholder="456 78 910"  v-model="division.name" />
                </a-form-item>
              </div>
              <div class="col-md-4">
                <a-form-item label="Org.nummer underenhet">
                  <a-input placeholder="456 78 910"  v-model="division.organizationNumber" />
                </a-form-item>
              </div>
              <div class="col-md-4">
                <a-form-item label="Avdeling">
                  <a-select :options="departments" v-model="division.department"/>
                </a-form-item>
              </div>
              <div class="col-md-6">
              <a-form-item label="Adress">
                <a-input placeholder="Slottsparken 1"  v-model="division.address.addressLine1" />
              </a-form-item>
              </div>
              <div class="col-md-2">
                <a-form-item label="Zip">
                  <a-input placeholder="0001" v-model="division.address.postalCode" />
                </a-form-item>
              </div>
              <div class="col-md-4">
                <a-form-item label="City">
                  <a-input placeholder="Oslo" v-model="division.address.city" />
                </a-form-item>
              </div>
              <div class="cui__utils__line" :style="{ marginTop: '25px', marginBottom: '30px' }"></div>
            </div>
            <div class="col-12">
              <a-form-item>
                <button type="secondary" class="btn btn-light px-5" @click.prevent="addDivision(i)">Ny underenhet</button>
              </a-form-item>
            </div>
            <div class="col-12">
              <a-form-item>
                <button type="primary" class="btn btn-light px-5">Lagre</button>
              </a-form-item>
            </div>
        </a-form>
      </a-collapse-panel>
    </a-collapse>
    <button type="secondary" class="btn btn-light px-5" @click="addCompany">Opprett ny selskap</button>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'

export default {
  data() {
    return {
      companies: [],
      departments: [],
      divisions: [],
    }
  },
  methods: {
    addCompany() {
      this.companies.push({
        id: 'NA',
        name: 'Ny Selskap',
        address: {},
        divisions: [],
        newCompany: true,
      })
    },
    addDivision(i) {
      const newDivision = {
        name: 'Ny Underenhet',
        address: {},
        startDate: '',
        endDate: '',
        organizationNumber: '',
        newDivision: true,
      }
      if (this.companies[i].divisions) {
        this.companies[i].divisions.push(newDivision)
      } else {
        this.companies[i].divisions = [newDivision]
      }
      console.log(this.companies[i])
    },
    handleSubmit(i) {
      const company = this.companies[i]
      console.log(company)
      firestore.collection('settings').doc('companies').collection('companies')
        .doc(company.id)
        .set(company)
        .then(() => {
          openNotification('Informasjon om selskapet lagret')
        })
    },
  },
  mounted() {
    firestore.collection('settings').doc('companies').collection('companies')
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const company = doc.data()
          company.id = doc.id
          company.newCompany = false
          this.companies.push(company)
        })
        console.log(this.companies)
        return firestore.collection('settings').doc('departments').collection('departments').get()
      }).then(snapshot => {
        snapshot.forEach(doc => {
          this.departments.push({
            value: doc.data().id,
            label: doc.data().name,
          })
        })
        console.log(this.departments, 'dep')
      })
  },
}
</script>
